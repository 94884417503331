import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nation',
  templateUrl: './nation.component.html',
  styleUrls: ['./nation.component.scss']
})
export class NationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
