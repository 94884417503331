<div id="footer" class="bg-gray-200 px-4">

  <!-- start container -->
  <div class="container mx-auto pt-8 pb-4">

    <div class="flex flex-wrap overflow-hidden items-center sm:-mx-1 md:-mx-px lg:-mx-2 xl:-mx-2 md:text-left text-center">
      <div class="w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 pb-6">
        <!-- Column 1 Content -->
        <h4 class="text-gray-600 font-bold">Quick Links</h4>
        <ul class="">
          <li id="link-2" class="leading-7 text-sm"><a class="text-gray-600 underline text-small" href="/publications">Publications</a></li>
          <li id="link-1" class="leading-7 text-sm"><a class="text-gray-600 underline text-small" href="/civilization">Newah Civilization</a></li>
          <li id="link-3" class="leading-7 text-sm"><a class="text-gray-600 underline text-small" href="/nation">Newah as a Nation</a></li>
          <li id="link-4" class="leading-7 text-sm"><a class="text-gray-600 underline text-small" href="/words-from-president">Words from President</a></li>
          <li id="link-5" class="leading-7 text-sm"><a class="text-gray-600 underline text-small" href="/events/upcoming-events">Upcoming Events</a></li>
        </ul>
      </div>

      <div class="w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 md:-ml-20 pb-6">
        <!-- Column 2 Content -->

        <h4 class="text-gray-600 font-bold">Info</h4>
        <ul class="">
          <li id="navi-1" class="leading-7 text-sm"><a class="text-gray-600 underline text-small" href="/footer/objectives">Objectives</a></li>
          <li id="navi-2" class="leading-7 text-sm"><a class="text-gray-600 underline text-small" href="/footer/bylaws">NSC Bylaws</a></li>
          <li id="navi-3" class="leading-7 text-sm"><a class="text-gray-600 underline text-small" href="/footer/lifemembers">Life Members</a></li>
          <li id="navi-4" class="leading-7 text-sm"><a class="text-gray-600 underline text-small" href="/footer/generalmembers">General Members</a></li>
          <li id="navi-5" class="leading-7 text-sm"><a class="text-gray-600 underline text-small" href="/footer/support">Support for COVID-19</a></li>
        </ul>
      </div>
      <div class="w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 md:-ml-20 pb-6 pt-8">
        <!-- Column 3 Content -->
        <h1 class="text-center text-md">
          Sign up for newsletter and updates
        </h1>

        <div class="flex justify-center mt-6">
          <div class="bg-white rounded-lg">
            <div class="flex flex-wrap justify-between md:flex-row">
              <input type="email" class="m-1 p-2 appearance-none text-gray-700 text-sm focus:outline-none" placeholder="Enter your email">
              <button class="w-full m-1 p-2 text-sm bg-gray-700 text-white rounded-md uppercase lg:w-auto">subscribe</button>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full overflow-hidden flex flex-wrap sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 ml-8 pb-6">
        <!-- Column 4 Content -->
        <div class="w-1/3">
        <img style="max-width: 80%;height:auto;" class="" src="../assets/image/logos/newah-logo.png" alt="Logo">
      </div>
        <div class="w-2/3 text-left">
        <h1 class="text-md font-bold">
          Newah Society of Calgary
        </h1>
          <p class="text-sm">
            Calgary, Alberta, Canada
          </p>
          <p class="text-sm">
            contact@newahcalgary.ca
          </p>
          <p class="text-sm">
            www.newahcalgary.ca
          </p>
        </div>
      </div>

    </div>



    <!-- Start footer bottom -->

<!--    <div class="pt-4 flex items-center justify-center text-center " style="border-top:1px solid white">-->
<!--      <ul class="">-->
<!--        <li class="mx-2 inline leading-7 text-xs" id="footer-navi-2"><a class="text-gray-600 underline text-small" href="/disclaimer">Disclaimer</a></li>-->
<!--        <li class="mx-2 inline leading-7 text-xs" id="footer-navi-2"><a class="text-gray-600 underline text-small" href="/cookie">Cookie policy</a></li>-->
<!--        <li class="mx-2 inline leading-7 text-xs" id="footer-navi-2"><a class="text-gray-600 underline text-small" href="/privacy">Privacy</a></li>-->
<!--      </ul>-->
<!--    </div>-->


    <!-- end container -->
  </div>



</div>
