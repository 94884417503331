<section class="relative block bg-gray-600 py-12 md:mt-0 mt-16">
  <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
       style="height: 80px; transform: translateZ(0px);">
    <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
         version="1.1" viewBox="0 0 2560 100" x="0" y="0">
      <polygon
        class="text-gray-600 fill-current"
        points="2560 0 2560 100 0 100"
      ></polygon>
    </svg>
  </div>
  <div class="container mx-auto px-4 lg:pt-24 pb-12 lg:pb-64">
    <div class="flex flex-wrap text-center justify-center">
      <div class="w-full lg:w-6/12 px-4">
        <h2 class="text-4xl font-semibold text-white">Contact Us</h2>
        <!--          <p class="text-lg leading-relaxed mt-4 mb-4 text-gray-300">-->
        <!--            Any queries or request -->
        <!--          </p>-->
      </div>
    </div>
  </div>
</section>


<!--  Contact Form-->
<section class="relative block py-24 lg:pt-0 bg-gray-600">
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
      <div class="w-full lg:w-6/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300"
        >
          <div class="flex-auto p-5 lg:p-10">
            <p class="leading-relaxed mt-1 mb-4 text-gray-800 font-semibold">
              Please fill up the form and submit for your concerns and suggestions
            </p>
            <div class="relative w-full mb-3 mt-8">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="fullName"
              >Full Name</label
              ><input
              type="text"
              id="fullName"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
              placeholder="Full Name"
              style="transition: all 0.15s ease 0s;"
            />
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="email"
              >Email</label
              ><input
              type="email"
              id="email"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
              placeholder="Email"
              style="transition: all 0.15s ease 0s;"
            />
            </div>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                for="message"
              >Message</label
              ><textarea
              rows="4"
              cols="80"
              id="message"
              class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
              placeholder="Type a message..."
            ></textarea>
            </div>
            <div class="text-center mt-6">
              <button
                class="bg-gray-700 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style="transition: all 0.15s ease 0s;"
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
