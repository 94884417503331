<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap md:mt-32 md:p-16 p-8">
      <div class="w-full rounded-lg h-80 overflow-hidden mb-6 flex justify-center items-center">
        <img src="assets/image/large/oillamps001.jpg"/>
      </div>

      <h3 class="text-3xl mb-2 font-semibold leading-normal">
        Newah as a Nation
      </h3>
      <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 text-justify">
        The most advanced community in the Himalayan foothills, The Newah realm has existed from ancient times. Scholars
        argue that it might be older than the Indus Valley civilization, but historians indicate it existed before the
        arrival of Buddhism (6th century BC).

        <br/><br/>
        Thriving as a rich society during the Kirati and Lichhavi period, it achieved excellence during the Malla Era
        between the 14th and the 18th centuries. Many Malla kings were themselves creators of poetry and under their
        auspices, art, culture, and unique traditions took great strides in a better direction.

        <br/><br/>
        We are aware that Arniko was the bridge that connected Nepal Mandala with Chinese empire in terms of transfer of
        Newah art to that country.

        <br/><br/>
        To recap, the Newah people come mostly from Tibeto- Burman or Mongoloid backgrounds. Though this is a major
        group, there also exists Newah of Indo-Aryan descent.

        <br/><br/>
        Scholars indicate Nepal and Newah words represent Nepal Mandala and may be interchangeable to one another.
        Kathmandu valley, known as Nepal until a few decades ago, was and is the epicenter of Newah. The valley has been
        at the crossroad of trade and culture and our Newah diversity is the result of this.

        <br/><br/>
        Let us look at some pertinent facts. When the social structure was defined by a Malla king,
        <br/><br/>
        we had Newah brahmins like Rajopadhyaya, Jha among the Hindus and Bajracharya for Buddhists. While Malla,
        Rajbhandari, Joshi etc. belonged to the Chhetriya class, businesspeople belonged to the Vaishya and servers were
        classified as Shudras. This indicates many tiers of social strata found in a Nation.

        <br/><br/>
        Jyapus are the true original Newars of Kathmandu Valley. Others are all migrants with roots in the north or
        south. Some Jyapus have Hindu priests while others have Bajracharya ones indicating a close bond between these
        two religions.

        <br/><br/>
        Any nation has multiple languages as we know. The Newah language also has many versions. Even in the valley, we
        find some differences in languages of three major cities.
        <br/><br/>
        A very different kind of Newari is spoken in Dolakha while the language spoken in the west Tansen, Pokhara etc.
        has a notable distinction.

        <br/><br/>
        The art, architecture and literature developed early in Newah country. Changu Narayan temple dates back to 325
        AD. A palm leaf art is believed to have been created in 1114 AD. Other very old icons are Budhanilkantha,
        Palnckowk etc.

        <br/><br/>
        In Europe, Renaissance, 15th to 18th centuries, is regarded as the period of rebirth of art culture etc. The
        Newah art and architecture are older than these. Some scholars argue that development of Newah heritage precedes
        European Renaissance and should be considered as the first Renaissance. The pagoda style temples are the
        original creations of Nepal Mandala and it was exported to many countries where some changes were made locally.

        <br/><br/>
        Interesting to note that unlike other Hindu societies Newah people consume buffaloes.
        <br/><br/>
        It is believed, during one of Malla king's rule a buffalo was offered to Taleju Bhawani to save the kingdom from
        a bad famine. And eating buffalo meat became normal.

        <br/><br/>
        In conclusion, the Newah community has all the characteristics of what we call a country.
        <br/><br/>
        We have our own food, dresses, festivities, and social norms.
        <br/><br/>
        As pointed out by some anthropologists or social scientists we are not a single race, ethnic group, or a tribal
        group.

        <br/><br/>
      </p>
      <div class="font-bold">We, the Newah, are a nation.</div>

    </div>
  </div>
</section>
