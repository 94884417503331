<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap p-32">
      <h3 class="text-3xl mb-2 font-semibold leading-normal">
        Objectives
      </h3>
      <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 text-justify">
        (1) To promote and practice "Nepal Bhasha" also known as "Newah" language ("Bhaye").
        <br/>
        (2) To promote and practice "Ranjana Lipi" or script used in Newah language, which is also registered script in
        UN-membership of Nepal.
        <br/>
        (3) To educate and disseminate information of UNESCO world heritage sites associated with Newah culture such as
        Swayambhu, Taleju, Nyata Pau & Changu Narayan temples & typical Newah habitats like "Bungamati", "Khokana" etc.
        <br/>
        (4) To educate its members and their descendants about unique events held in Nepal related to Newah culture in
        Nepal such as Rato Machhendra Nath Rath Yatra, Seto Machhendra Nath Rath Yatra, lndra Jatra, Kumari (living
        goddess) festival, "Dyo Lwakegu festival during PahnaChare" & "Gathemuga Chare" festival etc.
        <br/>
        (5) To educate & facilitate where feasible to perform rituals uniquely found in Newari tradition like "lhi",
        "Kayata Puja", "Janko", "Nasa Puja", "Gnu La Dharma Sewa" etc.
        <br/>
        (6) To educate and disseminate knowledge to its members and their descendants about historical events, folklore
        tales, Hindu, Buddhist tantric, Shaministic stories etc.
        <br/>
        (7) To celebrate festivals where unique Newah foods are served such as Yomari Punhi (Yomari), Sithi Nakha (wo,
        chatnaa mari), lndra Jatra (samaya baji) and also to educate the members about other unique Newah cuisines such
        as "ka-kwa (Bullah)", "Ta:kha" & "Sanya­ khuna" etc.
        <br/>
        (8) To celebrate Nepal Sambat and Swanti Nakha.
        <br/>
        (9) To maintain concept of "Guthi System" which is an essential social concept in Newah society.
        <br/>
        (10) To continue to maintain & promote an essential core concept of inter-religious harmony in Newari society to
        its members and their descendants by collectively observing Hindu, Buddhist and Shaministic festivals.
      </p>
    </div>
  </div>
</section>
