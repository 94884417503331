import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ObjectivesComponent} from './objectives/objectives.component';
import {BylawsComponent} from './bylaws/bylaws.component';
import {LifemembersComponent} from './lifemembers/lifemembers.component';
import {GeneralmembersComponent} from './generalmembers/generalmembers.component';
import {SupportComponent} from './support/support.component';


const routes: Routes = [
  {path: 'objectives', component: ObjectivesComponent },
  {path: 'bylaws', component: BylawsComponent},
  {path: 'lifemembers', component: LifemembersComponent},
  {path: 'generalmembers', component: GeneralmembersComponent},
  {path: 'support', component: SupportComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FooterRoutingModule { }
