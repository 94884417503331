<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap md:mt-32 md:p-16 p-8">
      <div class="w-full md:w-7/12 pb-4 mr-auto ml-auto">
        <h3 class="text-3xl mb-2 font-semibold leading-normal">
          Newah Society of Calgary
        </h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 text-justify">
          Newah Society of Calgary (NSC) is a non-profit community organization established in Calgary, Alberta in August 2020 and officially
          registered in the Corporate Registry of Government of Alberta on January 11, 2021, with the main objectives of promoting, preserving
          and revitalizing Newah language, culture and heritage.
        </p>
        <p class="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700 text-justify">
          The first formal Newah gathering in Calgary took place on September 9, 2017, on the occasion of Yenya Jatra (Indra Jatra). This very
          first Newah Program in Calgary was coordinated by three Newah leaders of Calgary including Mr. Laxman Shrestha and Mr. Dibya Pradhan.
          As a matter of fact, the idea of gathering and organizing Newah people in Calgary was first put forward by Mr. Laxman Shrestha during
          the 2017 election day of NRN Canada in Calgary few months earlier to the Yenya Jatra gathering. The idea was well taken by the Newah
          Community in Calgary and hence started to organize various Newah cultural events such as Yomari Punhi, Yenya Punhi, Sithi Nakha etc.
          Mha Puja was first celebrated in Calgary in a community level in 2006 and later it has been organized by Calgary Nepalese Community
          Association (CNCA). Moving forward, Mha Puja will be organized and celebrated with the leadership of NSC.
        </p>
        <p class="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700 text-justify">
          In the process of organizing and registering a Newah organization in Calgary, a Bylaw Task Force was formed comprising Messrs.
          Laxman Shrestha, Jagadish Kayastha, Pradyumna (Raju) Shrestha, Prajwal Pradhan, Susan Pakwan and Ms. Binita Shrestha on January 21, 2018.
          Despite the drafting of Bylaws in October 2018, the registration process could not take place and hence another seven-member
          Bylaw review committee including Messrs. Purushottam Shrestha, Laxman Shrestha, Prajwal Pradhan, Hemanta Maharjan, Pradyumna (Raju) Shrestha,
          and Dr. Bharat Man Shrestha was formed on May 2, 2020. After finalizing the Bylaws and with the active coordination and persuasion
          of Newah leaders of Calgary, an ad-hoc Committee of NSC consisting of the following members and advisors was formed on August 9, 2020,
          on International Indigenous Day.
        </p>
        <p class="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700 text-justify">
          The ad-hoc committee was given the responsibilities of registering the organization with the Corporate Registry of Alberta and organizing
          the first Annual General meeting of the Organization. After officially registering the organization, the ad-hoc committee organized the
          very first Annual General Meeting on July 24, 2021, at Edworthy Park in Calgary which elected the first executive board members of the
          Newah Society of Calgary. Recently we have elected our new executive board members for the year 2023-2025 after a successful Annual General Meeting
          on September 30, 2023, at Richmond Knb Community Hall in Calgary.
        </p>
        <p class="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700 text-justify">
          The NSC is at its nascent stage of development. We are planning to offer our members and general public variety of services like celebration
          of Newah cultural festivals, regular Nepal Bhasa (Newah Language) classes, and Newah cuisine classes. Through these activities and programs,
          NSC hopes to contribute to the enrichment of multi-cultural atmosphere in Calgary. Regular participation and contribution of its members are
          vital for the achievement of our common goals.
        </p>
      </div>
      <div class="w-full md:w-3/12 mr-auto ml-auto">
        <div class="border-4 px-4 py-4">
          <h4 class="mb-2 font-semibold text-center">
            Newah leaders of Calgary
          </h4>
          <div *ngFor="let person of leaderCommittee">
            <div  class="leader-information flex py-1">
              <div class="avatar w-1/5 p-1 flex justify-center items-center">
                <img
                  src={{person.imageUrl}}
                  class="w-32 rounded-full"
                  alt="Avatar"
                />
              </div>
              <div class="personal-detail w-3/5 ml-3 flex flex-grow flex-col justify-center">
                <div class="personal-detail-designation font-semibold">
                  <h5>{{person.designation}}</h5>
                </div>
                <div class="personal-detail-name font-medium">
                  <h5>{{person.name}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border-4 px-4 py-4 mt-4">
          <h3 class="mb-2 font-semibold text-center">
            Advisory Committee
          </h3>
          <div *ngFor="let person of advisoryCommittee">
            <div  class="leader-information flex py-1">
              <div class="avatar w-1/5 h-1/2 p-1 flex justify-center items-center">
                <img
                  src={{person.imageUrl}}
                  class="w-32 rounded-full"
                  alt="Avatar"
                />
              </div>
              <div class="personal-detail w-3/5 ml-3 flex flex-grow flex-col justify-center">
                <div class="personal-detail-designation font-semibold">
                  <h5>{{person.designation}}</h5>
                </div>
                <div class="personal-detail-name font-medium">
                  <h5>{{person.name}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="w-full md:w-8/12 mr-auto ml-auto">
    <div class="relative flex justify-center text-center w-full break-words bg-white  mb-6 shadow-lg  bg-red-600">
      <blockquote class="relative p-8 mb-4">

        <p class="text-3xl text-gray-200 text-center leading-tight">Vision:</p>
        <p class="text-center text-white px-10">Vibrant, harmonious and cohesive Newah Community as a significant component
          of the diverse and multicultural City of Calgary.
        </p><br>
        <p class="text-3xl text-gray-200 text-center leading-tight">Mission:</p>
        <p class="text-center text-white px-10">Preserve, promote, educate and revive Nepalbhasa (Newah Language), Newah
          culture, tradition and heritage by celebrating Newah festivals, organizing ritual events, conducting Nepalbhasa
          & cuisine classes, and participating in humanitarian & intercultural events
        </p>
      </blockquote>
    </div>
  </div>

</section>
