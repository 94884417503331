<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap md:mt-32 md:p-16 p-8">
      <div class="w-full md:w-7/12 pb-4 mr-auto ml-auto">
        <h3 class="text-3xl mb-2 font-semibold leading-normal">
          Newah Bwane Kuthi - Nepal Bhasa Class
        </h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 text-justify">
          Nepal Bhasa is known as Newah language is the predominant, oldest one in Nepal which is listed endangered
          caused
          by various factors including urbanization, the growing globalization of culture, media, and art. Endangered
          languages stand a greater chance of survival, understanding the culture, tradition, heritage in more depth,
          preservation of their culture, community’s history, costumes, memory, unique modes of thinking, meaning and
          expression, academic, ethnic and social values when they are used to speaking and writing.

          <br/><br/>

          Newah Society of Calgary (NSC) tends to achieve its main objective of preserving, promoting and revitalizing
          the
          Newah language to community members either adults or a new generation of the Newah population. So, NSC has
          been
          running the first phase of Nepal Bhasa Class biweekly since September 2021 for adult persons who need to
          reminisce
          words, sentences structures as well as learn basic language skills to understand and communicate with friends
          and
          family. Basically, the class covers greetings, pronunciation, counting numbers, body parts, foods, animals,
          relationships, times, homonyms and adjectives/adverbs topics. Initially, NSC focuses on adult parents to this
          program for making it easier to transfer and teach to their children. The second phase Nepal Bhasa class will
          be
          started for children with the kind coordination and collaboration of their trained parents in the Class.

          <br/><br/>

          Mr. Hemanta Maharjan is facilitating to run this program through the Microsoft Team biweekly by developing
          teaching materials, sharing and transferring knowledge and information to learners. Besides, the Executive
          Board
          Members and Nepalbhasa Class Committee also actively contribute their endeavor to fulfill NSC’s goal and to
          make
          them successful.
        </p>
      </div>

      <div class="w-full md:w-4/12 mr-auto ml-auto">
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-red-600">
          <img src="/assets/image/logos/newah-header-bw.png" class="m-10" alt="logo"/>
          <blockquote class="relative p-8 mb-4">
          </blockquote>
        </div>
      </div>
    </div>
  </div>
</section>
