<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap md:mt-32 md:p-16 p-4">
      <h3 class="text-3xl mb-2 font-semibold leading-normal mb-10">
        Our Publications (A NSC Newsletter)
      </h3>

      <div class="flex flex-wrap items-center w-full mb-6">
            <div class="w-full lg:w-6/12 mr-auto ml-auto">
              <p class="text-lg font-light leading-relaxed mt-0 text-gray-700">Select the publication you would like to view: </p>
            </div>

            <div class="w-full lg:w-6/12 lg:pl-4 mr-auto ml-auto">
              <select #pubs (change) ="onSelected(pubs.value)" name="cars" id="publication-select" class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <option class="block px-4 py-2 text-sm text-gray-700" [value]="item.id" *ngFor="let item of items">{{item.name}}</option>
              </select>
            </div>
        </div>

      <object [data]='pdfSrc'  type="application/pdf" width="100%" height="800px">
        It appears you don't have a PDF plugin for this browser. You can <a [href]="pdfSrc" class="font-bold underline"> click here to download the PDF file.</a>
      </object>
    </div>
  </div>
</section>
