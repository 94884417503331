import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './component/home/home.component';
import {AboutComponent} from './component/about/about.component';
import {CivilizationComponent} from './component/civilization/civilization.component';
import {NationComponent} from './component/nation/nation.component';
import {LanguageComponent} from './component/language/language.component';
import {PublicationsComponent} from './component/publications/publications.component';
import {PhotosComponent} from './component/photos/photos.component';
import {FooterRoutingModule} from './component/footer/footer-routing.module';
import {ContactComponent} from './component/contact/contact.component';
import {MembershipComponent} from './component/membership/membership.component';
import {WordsfrompresidentComponent} from './component/footer/wordsfrompresident/wordsfrompresident.component';
import {EventsRoutingModule} from './component/events/events-routing.module';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'civilization', component: CivilizationComponent},
  {path: 'nation', component: NationComponent},
  {path: 'language', component: LanguageComponent},
  {path: 'publications', component: PublicationsComponent},
  {path: 'photos', component: PhotosComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'membership', component: MembershipComponent},
  {path: 'words-from-president', component: WordsfrompresidentComponent},
  {path: 'footer', loadChildren: () => FooterRoutingModule},
  {path: 'events', loadChildren: () => EventsRoutingModule}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
