<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap md:p-32 p-6">
      <h3 class="text-3xl mb-2 font-semibold leading-normal">
        Support for Covid-19
      </h3>
      <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 text-justify">
        Newah Society of Calgary has deposited CAD 2,126.00 to the Society of American Nepalese Nurses (SANN) fundraiser
        benefiting Dr. Mahabir Pun led National Innovation Centre (NIC). Mr. Prajwal Pradhan has donated NRs. 20,000 to
        the NIC directly in Nepal through his contact.

        <br/><br/>

        List of donors:
        <br/>
        1. Newah Society of Calgary (Institutional) - $ 1050.00
        <br/>
        2. Phanindra Gyawali (Kusum) - $ 50.00
        <br/>
        3. Bharat Man Shrestha - $101.00
        <br/>
        4. Arun Shrestha - $ 100.00
        <br/>
        5. Kedar Lal Shrestha - $ 75.00
        <br/>
        6. Binita Shrestha - $ 50.00
        <br/>
        7. Udhav Shrestha - $ 50.00
        <br/>
        8. Purushottam Shrestha - $ 100.00
        <br/>
        9. Ramila Manandhar Chaulagain - $ 50.00
        <br/>
        10. Deepak Shrestha -$ 100.00
        <br/>
        11. Rabindra Thanju -$ 50.00
        <br/>
        12. Navaraj Shrestha -$ 50.00
        <br/>
        13. Hemanta Maharjan - $ 50.00
        <br/>
        14. Raju Baiji - $ 100.00
        <br/>
        15. Birendra Shrestha - $ 50.00
        <br/>
        16. Sumina Shrestha - $100.00
        <br/>
        16. Prajwal Pradhan - NRs. 20,000.00 (deposited in Nepal)
        <br/><br/><br/>

        <b class="font-bold">Total : CAD 2,126.00 + NRs. 20,000.00</b>
      </p>

      <div class="w-full mt-16 flex justify-center items-center">
        <img
          src="https://static.wixstatic.com/media/b35bea_d441f3efe6d34eeea9001bf332e3c04f~mv2.jpg/v1/fill/w_861,h_864,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Final_letter%20of%20appreciation%202021_07_26.jpg"/>
      </div>
    </div>
  </div>
</section>
