<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap mt-32 lg:p-16 md:p-16 p-4">
      <h3 class="text-3xl mb-2 font-semibold leading-normal mb-10">
        Life Members
      </h3>

      <object data="assets/document/lifemembers.pdf" type="application/pdf" width="100%" height="800px">
        <p>It appears you don't have a PDF plugin for this browser.
          You can <a href="assets/document/lifemembers.pdf" class="font-bold underline"> click here to
            download the PDF file.</a></p>
      </object>

    </div>
  </div>
</section>
