import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bylaw',
  templateUrl: './bylaw.component.html',
  styleUrls: ['./bylaw.component.scss']
})
export class BylawComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
