<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap mt-32 lg:p-16 md:p-16 p-4">
      <h3 class="text-3xl mb-2 font-semibold leading-normal mb-10">
        Words from President
      </h3>
      <div>
        <div class="p-6 md:w-3/12 w-full float-left relative">
          <img alt="..." src="./assets/image/small/puru001.png" class="align-middle rounded-lg"/>
        </div>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 text-justify">

          Welcome aboard! I would like to thank you for visiting our official website and your interest in Newah Society
          of Calgary (NSC), Nepal Bhasa (Newah language), Newah culture, and heritage.

          <br/><br/>

          It is our sincere effort to connect with all our members and well-wishers through this media besides our
          normal modus such as emails, online meetings, in-person meetings and various programs. Please feel free to
          drop a few words to improve this website so that one can have better understanding of our organization and
          its activities. Thanks to many volunteers for developing this wonderful site!

          <br/><br/>

          If you have already gone through some of the sections of this website before visiting this section, you must
          have been familiar with our objectives and programs. I would encourage you to visit different sections of
          this website if you have not done so.

          <br/><br/>

          The main objective of NSC is to preserve, promote and revitalize Nepal Bhasa, Newah culture and heritage. I
          would like to draw your attention to a bitter fact that Nepal Bhasa has been on the UNESCO list of
          endangered languages as a ‘definitely endangered language’. There have been some recent developments in
          Nepal (such as development and implementation of Nepal Bhasa curriculum at the school level in Kathmandu
          Metropolitan City) and abroad (such as process of inclusion of Nepal Bhasa in Google Translation), which
          will help to revitalize our ‘definitely endangered’ Nepal Bhasa. However, we should not be complacent with
          these activities only. I would like to emphasize that unless we use our language with ourselves and with our
          successive generations, the language is going to die. The same applies to our culture and heritage as well.

          <br/><br/>

          We are in the very first year of establishment and we have significant number of life and general members.
          Thank you, all the life members and general members, for being the part of our great mission. I would like
          to request all the Newah brothers, sisters and well-wishers of Newah language, culture, and heritage,
          residing in and around Calgary and beyond to be the part of Newah Society of Calgary. Our unity and
          concerted efforts can bring some positive changes to our community, our language, our culture, and our
          heritage. Nation flourishes if cultures flourish, culture flourishes if the language is alive!

          <br/><br/>
          Yours Truly,
          <br/><br/>
        </p>
        <div class="w-full">
          <p class="text-md text-gray-900 font-semibold text-center">Purushottam Shrestha</p>
          <p class="text-sm text-gray-500 text-center">President, Executive Committee (2021-2023)</p>
        </div>
      </div>
    </div>
  </div>
</section>
