<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap md:mt-24 sm:mt-16 md:p-16 p-4">
      <h3 class="md:text-3xl text-xl mb-2 font-semibold leading-normal">
        Photos from Major Events
      </h3>
      <section class="overflow-hidden text-gray-700">
        <div class="container md:px-5 py-2 mx-auto lg:pt-16">
          <div class="flex flex-wrap -m-1 md:-m-2">
            <div class="flex flex-wrap">
              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/newahclass1.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/newahclass2.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/newahclass3.jpg">
              </div>
              <div class="w-1/3 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/newahclass4.jpg">
              </div>
              <div class="w-1/3 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/newahclass5.jpg">
              </div>
              <div class="w-1/3 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/newahclass6.jpg">
              </div>

              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/BuddhaJayanti1.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/BuddhaJayanti2.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/BuddhaJayanti3.jpg">
              </div>
              <div class="w-1/3 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/BuddhaJayanti4.jpg">
              </div>
              <div class="w-1/3 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/BuddhaJayanti5.jpg">
              </div>
              <div class="w-1/3 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/BuddhaJayanti6.jpg">
              </div>


              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/HighwayCleanup1.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/HighwayCleanup2.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/HighwayCleanup3.jpg">
              </div>
              <div class="w-1/3 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/HighwayCleanup4.jpg">
              </div>
              <div class="w-1/3 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/HighwayCleanup5.jpg">
              </div>
              <div class="w-1/3 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/HighwayCleanup6.jpg">
              </div>

              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/AGM_1.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/AGM_2.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/AGM_3.jpg">
              </div>

              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Mela_4.jpg">
              </div>
              <div class="w-1/4 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Mela_2.jpg">
              </div>
              <div class="w-1/4 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Mela_5.jpg">
              </div>
              <div class="w-1/4 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Mela_!.jpg">
              </div>
              <div class="w-1/4 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Mela_3.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Picnic_5.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Picnic_6.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Picnic_3.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Picnic_4.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Picnic_2.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Picnic_1.jpg">
              </div>

              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee_11.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee_10.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee_12.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee_10.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee_12.jpg">
              </div>

              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Yamari_1.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Yamari_2.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Yamari_3.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Yamari_4.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Yamari_5.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Yamari_6.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/HCU_1.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/HCU_2.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/HCU_3.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/HCU_4.jpg">
              </div>

              <div class="w-1/4 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/13.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/15.jpg">
              </div>
              <div class="w-1/4 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/14.jpg">
              </div>

              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/16.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/1.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/3.jpg">
              </div>
              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/2.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/10.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/8.jpg">
              </div>
              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/9.png">
              </div>

              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/4.jpg">
              </div>

              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/5.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/6.jpg">
              </div>
              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/12.jpg">
              </div>

              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/11.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/7.jpg">
              </div>

              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee1.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee3.jpg">
              </div>
              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee2.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee7.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee8.jpg">
              </div>
              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee9.jpg">
              </div>

              <div class="w-full p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee4.jpg">
              </div>

              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee5.jpg">
              </div>
              <div class="w-1/2 p-1 md:p-2">
                <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                     src="assets/image/gallery/Newah_Ehee6.jpg">
              </div>

<!--              <div class="w-full p-1 md:p-2">-->
<!--                <video width="100%" height="100%" controls>-->
<!--                  <source src="Video.mp4" type="video/mp4">-->
<!--                  Your browser does not support the video tag.-->
<!--                </video>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
