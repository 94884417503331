import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bylaws',
  templateUrl: './bylaws.component.html',
  styleUrls: ['./bylaws.component.scss']
})
export class BylawsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
