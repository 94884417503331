<section class="py-8 bg-gray-200">
  <div class="container mx-auto">
    <div class="flex flex-wrap m-20 p-16">
      <div class="w-full md:w-5/12 pb-4 mr-auto ml-auto">
        <h3 class="text-3xl text-gray-600 mb-8 font-semibold leading-normal">
          Join Newah Society of Calgary
        </h3>
        <form class="block border rounded-lg bg-gray-400 p-6 w-full max-w-lg">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-first-name">
                First Name
              </label>
              <input
                class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:shadow-outline focus:border-gray-500"
                id="grid-first-name" type="text" placeholder="">
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-last-name">
                Last Name
              </label>
              <input
                class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:shadow-outline focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="">
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-first-name">
                Phone
              </label>
              <input
                class="appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:shadow-outline focus:border-gray-500"
                id="grid-phone" type="text" placeholder="">
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-last-name">
                Email
              </label>
              <input
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:shadow-outline focus:border-gray-500"
                id="grid-email" type="text" placeholder="">
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-first-name">
                City
              </label>
              <input
                class="appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:shadow-outline focus:border-gray-500"
                id="grid-phone" type="text" placeholder="">
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-last-name">
                Province
              </label>
              <input
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:shadow-outline focus:border-gray-500"
                id="grid-email" type="text" placeholder="">
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-city">
                Street Address
              </label>
              <input
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:shadow-outline focus:border-gray-500"
                id="grid-city" type="text" placeholder="">
            </div>
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-zip">
                Postal Code
              </label>
              <input
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:shadow-outline focus:border-gray-500"
                id="grid-zip" type="text" placeholder="A0A 0A0">
            </div>
          </div>
          <div class="flex flex-wrap mb-2">
            <div class="mt-4">
              <label class="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-zip">
                Membership Type
              </label>
              <div class="mt-2">
                <label class="inline-flex items-center text-gray-700 text-sm font-bold">
                  <input type="radio" class="form-radio" name="accountType" value="life">
                  <span class="ml-2">Life Membership ($200)</span>
                </label>
                <label class="inline-flex items-center text-gray-700 text-sm font-bold ml-6">
                  <input type="radio" class="form-radio" name="accountType" value="general">
                  <span class="ml-2">General Membership ($15 per year)</span>
                </label>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <label class="block">
              <input class="mr-2 leading-tight" type="checkbox">
              <span class="text-sm font-semibold">
                I acknowledge that I have read and am aware of the followings:
              </span>
            </label>
          </div>
          <ol class="list-decimal font-light text-sm leading-relaxed mt-0 mb-4 pl-4 text-gray-700">
            <li>
              I will abide by the laws and regulations of NSC and Canada.
            </li>
            <li>
              I grant permission to use my audio or video or any picture for the NSC publications and
              social media sites, and understand that I reserve my right to request to remove or delete
              contents shared or published by the NSC at any point of time in the future.
            </li>
          </ol>
          <div class="text-center mt-6">
            <button
              class="bg-gray-700 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button" style="transition: all 0.15s ease 0s;">Submit
            </button>
          </div>
        </form>
      </div>
      <div class="w-full md:w-4/12 mt-20 mr-auto ml-auto">
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-red-600">
          <img alt="..." src="./assets/image/medium/lakhey001.png" class="w-full align-middle rounded-t-lg"/>
          <blockquote class="relative p-8 mb-4">
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 583 95"
              class="absolute left-0 w-full block"
              style="height: 95px; top: -94px;"
            >
              <polygon
                points="-30,95 583,95 583,65"
                class="text-red-600 fill-current"
              ></polygon>
            </svg>
            <h4 class="text-xl font-bold text-white">
              The Newah Civilization
            </h4>
            <p class="text-md font-light mt-2 text-white">
              Newah people or Newars are the indigenous inhabitants of Kathmandu Valley and its surrounding
              areas in Nepal. Nepal Bhasa (also known as Newah language) is the common language among
              the Newah people. Nepal Bhasa, believed to be developed during Kirat period (circa 12th century),
              was Nepal’s administrative language from the 14th to the late 18th century.
            </p>
            <a href="#" class="font-bold text-yellow-400 mt-8">more...</a>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
</section>
