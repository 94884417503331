<section class="py-20 bg-gray-300">
  <div class="container mx-auto">
    <div class="flex flex-wrap md:mt-32 md:p-16 p-8">
      <h3 class="text-3xl mb-2 font-semibold leading-normal">
        Newah Civilization
      </h3>
      <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 text-justify">
        Newah people or Newars are the indigenous inhabitants of Kathmandu Valley and its surrounding areas in Nepal.
        Nepal Bhasa (also known as Newah language) is the common language among the Newah people. Nepal Bhasa,
        believed to be developed during Kirat period (circa 12th century), was Nepal’s administrative language from
        the 14th to the late 18th century.
      </p>

<!--      Civilizarion- Cuisine-->
      <div class="w-full relative flex flex-wrap break-words mb-6 bg-white">
        <div class="lg:w-4/12 p-6">
          <img alt="..." src="./assets/image/about/cuisine.jpg"
               class="align-middle rounded-lg w-full h-full object-cover"/>
        </div>

        <blockquote class="lg:w-8/12 relative text-justify p-6">
          <h4 class="text-xl font-bold text-3xl opacity-50">
            Cuisine
          </h4>
          <p class="text-md font-light mt-2">
            Newari people make different kinds of food on different occasions, representing their own cultural and
            ethical
            values. Newa cuisine is a subset of Nepalese cuisine that has developed over centuries among the Newars of
            Nepal. Newa cuisine is the most celebrated food variety in the country that consists of over 200 dishes that
            is rich in flavor, authenticity, healthy and hygienic. Newars have frequent traditional celebrations and
            festivals where food plays a major role. Newari cuisine consists of many different dishes, many of which are
            cooked with a generous amount of spices like cumin, turmeric, pepper, cinnamon, ginger, garlic, chilies,
            mustard oil and seeds. For meat, buffalo is most common and most beloved, but chicken, goat, and fish, are
            also common. The Newari staple is beaten rice, known as baji or chiura commonly used in festivals, feast and
            as a snack in afternoon meals. Rice, soup of beans, green vegetables are common food stuffs at lunch and
            dinner.
          </p>
        </blockquote>
      </div>

<!--      Civilization - Costume  -->
      <div class="w-full relative flex flex-wrap break-words mb-6 bg-white">
        <div class="lg:w-4/12 p-6">
          <img alt="..." src="./assets/image/about/costume.jpg"
               class="align-middle rounded-lg w-full h-full object-cover"/>
        </div>
        <blockquote class="lg:w-8/12 relative p-6 text-justify">
          <h4 class="text-xl font-bold text-3xl opacity-50">
            Costume
          </h4>
          <p class="text-md font-light mt-2">
            Women wear black cotton saris with a red border known as hāku patāsi or hāku parsi. It is still widely used
            especially among farmer women as everyday wear and is the most popular dress during festive occasions. A
            blouse fastened with cloth ties called misālan is worn with the sari. Most of the clothes are made of
            homespun. Many people had handlooms in their homes, and in the old days a spinning wheel and a seed
            separator
            were one of the required bridal gifts. People wove cloth for personal use or for sale. Men's common costume
            consists of a long shirt called tapālan and tight-fitting trousers known as suruwā. A waistcoat and coat may
            be worn over the shirt. This traditional dress is also worn during wedding processions.
          </p>
        </blockquote>
      </div>

<!--      Divider line  -->
      <div class="border-2 border-black w-full h-1 my-12"></div>

<!--      Life Events   -->
      <h3 class="text-3xl mb-2 font-semibold leading-normal">
        Newah Life Events (Traditions)
      </h3>
      <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700 text-justify">
        Girls from Newah community are married thrice. But not all three times to men. They are first made to marry a
        bael fruit, also known as wood apple. This ceremony known as ‘Ihi, Ehee or Bel Bibaha’ in Nepal is performed
        when the girl is at preadolescence i.e. before hitting puberty. They are then married to the Sun, before finally
        getting married to man.
      </p>

      <!--      Life Event Ihi-->
      <div class="w-full relative break-words mb-6 py-12 bg-gray-500">
        <div class="p-6 md:w-4/12 sm:w-48 float-right">
          <img alt="..." src="./assets/image/about/ihi.jpg" class="align-middle rounded-lg"/>
        </div>

        <blockquote class="relative text-justify p-6">
          <h4 class="lg:text-7xl md:text-6xl sm:text-5xl text-3xl font-bold opacity-50 text-white">
            Ihi
          </h4>
          <p class="text-md font-light mt-2 text-white">
            Description automatically generatedIhi, Ehee is a ceremony in the Newah community in Nepal in which
            pre-adolescent girls are "married" to the Suvarna Kumar which is a symbol of the God 'Vishnu' ensuring that
            the girl becomes and remains fertile. It is believed that if the girl's husband dies later in her life, she
            is not considered a widow because she is married to Vishnu, and so already has a husband that is believed to
            be still alive.
          </p>
        </blockquote>
      </div>

      <!--      Life Event Barah-->
      <div class="w-full relative break-words mb-6 py-12">
        <div class="p-6 md:w-4/12 sm:w-48 float-right">
          <img alt="..." src="./assets/image/about/barah.jpg" class="align-middle rounded-lg"/>
        </div>

        <blockquote class="relative text-justify p-6">
          <h4 class="lg:text-7xl md:text-6xl sm:text-5xl text-3xl font-bold opacity-50">
            Barah
          </h4>
          <p class="text-md font-light mt-2">
            Bahra tayegu or bahra chuyegu is a coming-of-age ritual in Newah community of Nepal in which girls between
            the ages of 7 to 13 are married to the sun God in a 12-day ceremony. Bahra tayegu is the second marriage of
            a Newaih girl.
            <br/><br/>
            This is done to purify the girl before her marriage to the sun God on the 12th day.
            The ceremony is conducted before the menarche generally at the age of 5, 7, 9, 11,
            or 13 year old.
          </p>
        </blockquote>
      </div>

      <!--      Life Event Keyta-Puja (Bratabandha)-->
      <div class="w-full relative break-words mb-6 bg-white py-12">
        <div class="p-6 md:w-4/12 sm:w-48 float-right">
          <img alt="..." src="./assets/image/about/bratabandha.jpg" class="align-middle rounded-lg"/>
        </div>

        <blockquote class="relative text-justify p-6">
          <h4 class="lg:text-7xl md:text-6xl sm:text-5xl text-3xl font-bold opacity-50">
            Keyta-Puja (Bratabandha)
          </h4>
          <p class="text-md font-light mt-2">
            The ritual is called Bratabandha in Nepali and Ketapuja in Newah. The ritual is performed before the boy
            becomes a teenager. It is a religious ritual performed by a priest. The boy is shaved as part of the ritual,
            stripped almost naked and afterward dressed in a new suit and a white scarf. Afterwards he is with all the
            men in the family. In Newah tradition it is usually performed between the age 5 and 10. It is
            mandatory before marriage for Newah men.
          </p>
        </blockquote>
      </div>

      <!--  Life Event Ihipa (Wedding)-->
      <div class="w-full relative break-words mb-6 bg-red-600 py-12">
        <div class="p-6 md:w-4/12 sm:w-48 float-right">
          <img alt="..." src="./assets/image/about/marriage.jpg" class="align-middle rounded-lg"/>
        </div>

        <blockquote class="relative text-justify p-6">
          <h4 class="lg:text-7xl md:text-6xl sm:text-5xl text-3xl font-bold opacity-50 text-white">
            Ihipa (Wedding)
          </h4>
          <p class="text-md font-light mt-2 text-white">
            The third, or the first non-divine marriage, of a Newah girl is with a man. The marriage happens as per the
            beliefs of the family. However, since the girl has already been married twice, the presence of the husband
            is not mandatory. Among the Newahs the responsibility for marriage arrangement depends wholly on the
            parents.
            In a traditional marriage, the father employs friends to search for suitable girls for his sons, and when
            one
            is found he sends an intimate friend of the girl's parents as a Lami or go-between to commence negotiations
            with her family. If the two families agree to negotiate, the girl's parents send their daughter's horoscope
            to the boy's parents through the Lami. The marriage ceremony only occurs when both of boy and girl's
            horoscope
            will be matched. Cross-Cousin and parallel-cousin marriage is forbidden.
          </p>
        </blockquote>
      </div>

      <!--      Life Event NEWARI JANKO CELEBRATION-->
      <div class="w-full relative break-words mb-6 py-12">
        <div class="p-6 md:w-4/12 sm:w-48 float-right">
          <img alt="..." src="./assets/image/about/janku.jpg" class="align-middle rounded-lg"/>
        </div>

        <blockquote class="relative text-justify p-6">
          <h4 class="lg:text-7xl md:text-6xl sm:text-5xl text-3xl font-bold opacity-50">
            Newari Janko Celebration
          </h4>
          <p class="text-md font-light mt-2">
            According to Newari tradition, a special birthday ceremony
            (Janko) is performed with family members, close relatives, and other neighbors with a special worship when
            Newari couple, man or woman turns 77 years, 7 months, 7 days, 7 hours age. The date for celebrating Janku
            day is calculated by the priest on the basis of lunar calendar. The various rituals are performed in order
            to please particular deities to help the person/couple overcome any hurdles which might pose a serious
            threat to the long life. One of the very important ritual of Janko ceremony is the parading of the honored
            person/couple around the vicinity of his home in a large procession. It is interesting to note that Janko
            celebration of husband and wife will be based on man’s age, but it will be celebrated together for the
            couple irrespective of the wife’s age at the time. The honored person/couple will be treated as a bride and
            groom and will be carried on a colorful Chariot around the local street with all his relatives as followers.
            All participants will get blessings from honored person in this special occasion. If lucky, five Janku will
            be performed during the lifetime of the person when they reach the milestone ages between 77 and 106 years
            old as follows.
          </p>
          <p class="text-md font-light mt-2">
            <b class="font-bold">First Janku</b> is performed at the age of 77 years, 7months, 7days, 7 hours, where
            they worship the sun
            as a god called
            BHIMRATHAROHAN. Grandsons carry the person’s chariot into their shoulders or make a round and visit the
            temples of god and goddess.

            <br/><br/>
            The <b class="font-bold">second Janku</b> is at the age of 83 years, 4 months, and 4 days where they are
            worshipped as an aspect
            of the moon. This is called CHANDRARATHAROHAN as it is believed that a person has seen 1000 full moons in
            their life and their prayers are directed towards the moon.

            <br/><br/>
            The <b class="font-bold">third Janku</b> is performed at the age of 88 years, 8 months and 8 days, which is
            called DEVRATHAROHAN
            where they are worshipped as an aspect of god.

            <br/><br/>
            The <b class="font-bold">fourth Janku</b> is at the age of 99 years, 9 months and 9 days, which is called
            DIVYARATHAROHAN.

            <br/><br/>
            The <b class="font-bold">fifth one</b> is at the age of 105 years, 8 months and 8 days old, called
            MAHADIVYARATHAROHAN.

            <br/><br/>
            The honored person or couple is considered as God after performing these rituals.
          </p>

        </blockquote>
      </div>

      <!--      Life Event Death Rituals -->
      <div class="w-full relative break-words mb-6 bg-white py-12">
<!--        <div class="p-6 md:w-4/12 sm:w-48 float-right">-->
<!--          <img alt="..." src="./assets/image/medium/lakhey001.png" class="align-middle rounded-lg"/>-->
<!--        </div>-->

        <blockquote class="relative text-justify p-6">
          <h4 class="lg:text-7xl md:text-6xl sm:text-5xl text-3xl font-bold opacity-50">
            Death Rituals
          </h4>
          <p class="text-md font-light mt-2">
            In Newah society, death is considered as a public and cosmic event (Pradhan, 1996) and rituals are as
            important for those who have died as for those who are alive. However, the death ritual varies from place to
            place from funeral to 13-day mourning. Funerals may take place on the same day of the death, but in most
            cases occur on the following day. Where there is presence of an organization responsible of death rituals,
            called “sī guthi”, it carries out funeral processions when a death occurs in a house of its members.
            Normally if an old person dies, sons of the deceased must bear the dead body to the cremation ground with
            help of relatives and neighbours. At the cremation ground, the chief mourner (usually the oldest son) lights
            the body to burn it down to ashes. The chief mourner and any other sons then shave their heads and bathe in
            the river. Returning home, sons of the deceased keep their distance from others for ten days during which
            time no one can touch them, they are called “Kiriya putri”.

            <br/><br/>

            Death rituals are believed to send departed souls from preta (ghost) to pitri-aatma (ancestor). It is
            believed that a dead soul roams around as preta (hungry soul) thus, it needs to be fed offering the “pinda”
            and water every day until it reaches to pitri-atma. The preta-atma reaches to pitri-atma after 10 days after
            receiving daily pindas. It is believed that if mortuary rites are not correctly performed, the deceased soul
            will forever remain as a preta and will not be incorporated as a pitiri, a terrible state for the departed
            spirit as well as for the living relatives whom it will cause trouble.

            <br/><br/>

            Daughters have a role to satisfy pretatma by preparing food and feeding cows on the seventh day. Ritual is
            performed by mediators like Bha, Kusle, Karmacharya and Brahmans in Hindu Newah society. On the days 1-10th
            married daughters are responsible to donate things like cooked food and clothes and a bed to Bha, and Kusle
            who accepts donations on behalf of pretatam of the deceased person. While chief mourners donate shoes and
            bed on 13th day to Karmacharya and Brahman who accepts donations on behalf of Pitri-atma of the deceased
            person. The relatives of the deceased person mourn for a year and no auspicious rituals will be celebrated.
            Annual Memorial Day (shradda) is performed each year as per lunar calendar afterward on the same tithi, the
            person was dead. Donations will be in various forms in the name of Pitri to Brahman in each Shradda tithi.
          </p>

        </blockquote>
      </div>
    </div>
  </div>
</section>
